*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 141 50% 60%;
  --pf: 141 50% 48%;
  --sf: 219 96% 48%;
  --af: 10 81% 45%;
  --nf: 219 20% 20%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --pc: 151 28% 19%;
  --s: 219 96% 60%;
  --sc: 210 20% 98%;
  --a: 10 81% 56%;
  --ac: 210 20% 98%;
  --n: 219 20% 25%;
  --nc: 210 20% 98%;
  --b1: 0 0% 100%;
  --bc: 219 20% 25%;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  height: 1.25rem;
  width: -moz-fit-content;
  width: fit-content;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  height: 3rem;
  min-height: 3rem;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn.loading, .btn.loading:hover {
  pointer-events: none;
}

.btn.loading:before {
  height: 1rem;
  width: 1rem;
  content: "";
  border-width: 2px;
  border-color: #0000 currentColor currentColor #0000;
  border-radius: 9999px;
  margin-right: .5rem;
  animation: spin 2s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .btn.loading:before {
    animation: spin 10s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-group > input[type="radio"].btn {
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group {
  width: 100%;
  align-items: stretch;
  display: flex;
}

.input-group > .input {
  isolation: isolate;
}

.input-group > *, .input-group > .input {
  border-radius: 0;
}

.input-group :where(span) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.input-group :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
}

.input-group :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu > :where(li.disabled > :not(ul):focus) {
  cursor: auto;
}

.btn-outline .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  background-color: #0000;
}

.btn-outline:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.btn-outline:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--ac) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > .disabled:hover, .btm-nav > [disabled], .btm-nav > [disabled]:hover {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .95));
  animation: none;
}

.btn:hover, .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
}

.btn:focus-visible {
  outline: 2px solid hsl(var(--nf));
  outline-offset: 2px;
}

.btn-primary:hover, .btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
}

.btn-secondary:hover, .btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
}

.btn-accent:hover, .btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af, var(--a)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity));
}

.btn-info:hover, .btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.btn-success:hover, .btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.btn-warning:hover, .btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.btn-error:hover, .btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.btn.glass:hover, .btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn.glass:focus-visible {
  outline: 2px solid;
}

.btn-ghost:hover, .btn-ghost.btn-active {
  --tw-border-opacity: 0;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
}

.btn-link:hover, .btn-link.btn-active {
  background-color: #0000;
  border-color: #0000;
  text-decoration-line: underline;
}

.btn-disabled, .btn-disabled:hover, .btn[disabled], .btn[disabled]:hover {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn.loading.btn-square:before, .btn.loading.btn-circle:before {
  margin-right: 0;
}

.btn.loading.btn-xl:before, .btn.loading.btn-lg:before {
  height: 1.25rem;
  width: 1.25rem;
}

.btn.loading.btn-sm:before, .btn.loading.btn-xs:before {
  height: .75rem;
  width: .75rem;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline: 2px solid hsl(var(--p));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.label a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.menu li.disabled > * {
  -webkit-user-select: none;
  user-select: none;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.menu li.disabled > :hover {
  background-color: #0000;
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.table tr.active th, .table tr.active td, .table tr.active:nth-child(2n) th, .table tr.active:nth-child(2n) td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.glass, .glass:hover, .glass.btn-active {
  -webkit-backdrop-filter: blur(var(--glass-blur, 40px));
  -webkit-backdrop-filter: blur(var(--glass-blur, 40px));
  backdrop-filter: blur(var(--glass-blur, 40px));
  background-color: #0000;
  background-image: linear-gradient( 135deg, rgb(255 255 255 / var(--glass-opacity, 30%))0%, #0000 100% ), linear-gradient( var(--glass-reflex-degree, 100deg), rgb(255 255 255 / var(--glass-reflex-opacity, 10%))25%, #0000 25% );
  box-shadow: 0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%))inset, 0 0 0 2px #0000000d;
  text-shadow: 0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%));
  border: none;
}

.badge-sm {
  height: 1rem;
  padding-left: .438rem;
  padding-right: .438rem;
  font-size: .75rem;
  line-height: 1rem;
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-sm > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-block {
  width: 100%;
}

.btn-square:where(.btn-xs) {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  height: 2rem;
  width: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 9999px;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  padding: 0;
}

.input-sm {
  height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 2rem;
}

.btn-group .btn:not(:first-of-type):not(:last-of-type), .btn-group.btn-group-horizontal .btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.btn-group .btn:first-of-type, .btn-group.btn-group-horizontal .btn:first-of-type {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group .btn:last-of-type, .btn-group.btn-group-horizontal .btn:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group.btn-group-vertical .btn:first-of-type {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: -1px;
  margin-left: 0;
}

.btn-group.btn-group-vertical .btn:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-4 {
  margin-right: 1rem;
}

.flex {
  display: flex;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-6 {
  height: 1.5rem;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-min {
  width: min-content;
}

.w-6 {
  width: 1.5rem;
}

.max-w-xs {
  max-width: 20rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.bg-base-content {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pr-3 {
  padding-right: .75rem;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-extralight {
  font-weight: 200;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-50 {
  opacity: .5;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

/*# sourceMappingURL=index.b5993c51.css.map */
